import { FilterFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Popover, Select, Space, Spin, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { ReactNode, useContext, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { fetchDashboardOptions, updateChart } from "../../../../../store/slices/dashboard";
import { RootState, useAppDispatch } from "../../../../../store/store";
import { HBEventName } from "../../../../../types/analyticsTypes/HBEvent";
import { DashboardFilterType, UserFilter } from "../../../../../types/dashboard";
import useInitTrackEvents from "../../../../../utils/hooks/useInitTrackEvents";
import HBRangeDatePicker from "../../../../HBComponents/RangeDatePicker/HBRangeDatePicker";
import { ChartContext } from "../../HBChart";

import "../../../Dashboard.less";

dayjs.extend(utc);
dayjs.extend(quarterOfYear);

const { Option } = Select;

export enum DateRange {
  DashboarDateRangeToday = "Today",
  DashboarDateRangeYesterday = "Yesterday",
  DashboarDateRangeThisWeek = "This week",
  DashboarDateRangeThisMonth = "This month",
  DashboarDateRangeThisQuarter = "This quarter",
  DashboarDateRangeThisYear = "This year",
  DashboarDateRangeLastWeek = "Last week",
  DashboarDateRangeLastMonth = "Last month",
  DashboarDateRangeLastQuarter = "Last quarter",
  DashboarDateRangeLastYear = "Last year",
}

export const ranges: { [key in DateRange]: [dayjs.Dayjs, dayjs.Dayjs] } = {
  Today: [dayjs().utcOffset(0), dayjs().utcOffset(0)],
  Yesterday: [dayjs().utcOffset(0).subtract(1, "day"), dayjs().utcOffset(0).subtract(1, "day")],
  "This week": [dayjs().utcOffset(0).startOf("week"), dayjs().utcOffset(0).endOf("week")],
  "This month": [dayjs().utcOffset(0).startOf("month"), dayjs().utcOffset(0).endOf("month")],
  "This quarter": [dayjs().utcOffset(0).startOf("quarter"), dayjs().utcOffset(0).endOf("quarter")],
  "This year": [dayjs().utcOffset(0).startOf("year"), dayjs().utcOffset(0).endOf("year")],
  "Last week": [
    dayjs().utcOffset(0).subtract(1, "weeks").startOf("week"),
    dayjs().utcOffset(0).subtract(1, "weeks").endOf("week"),
  ],
  "Last month": [
    dayjs().utcOffset(0).subtract(1, "months").startOf("month"),
    dayjs().utcOffset(0).subtract(1, "months").endOf("month"),
  ],
  "Last quarter": [
    dayjs().utcOffset(0).subtract(1, "quarter").startOf("Q"),
    dayjs().utcOffset(0).subtract(1, "quarter").endOf("Q"),
  ],
  "Last year": [
    dayjs().utcOffset(0).subtract(1, "year").startOf("year"),
    dayjs().utcOffset(0).subtract(1, "year").endOf("year"),
  ],
};

export default function ChartFilterMenu() {
  const { chart } = useContext(ChartContext);
  const dispatch = useAppDispatch();
  const { track } = useInitTrackEvents();
  const { t } = useTranslation();
  const primaryColor = useSelector((state: RootState) => state.common.primaryColor);
  const { options: filterOptions, isLoading } = useSelector((state: RootState) => state.dashboard.filterOptions);
  const [addDateFilterr, setaddDateFilterr] = useState<boolean>(false);
  const options = chart?.userFilters || [];

  const onFilterSelected: (filter: UserFilter) => (values: string[]) => void = filter => values => {
    if (chart?.filters && filter) {
      const updatedFilters = { ...chart.filters };
      updatedFilters[`${filter.field}-${filter.type}`] = values;
      dispatch(
        updateChart({
          ...chart,
          filters: updatedFilters,
        })
      );
      track({
        eventName: HBEventName.ChartFiltered,
        data: { chartId: chart.id, filter: filter.field },
      });
    }
  };

  const onDateFilterUpdated: (
    filter: UserFilter,
    dateFilterIndex: number
  ) => (values: [string | null, string | null] | null) => void = (filter, dateFilterIndex) => values => {
    if (chart?.filters && filter) {
      const updatedFilters = cloneDeep(chart.filters);
      if (values) {
        if (values[0] != undefined && values[0] != "" && values[1] != undefined && values[1] != "") {
          const filterCurrentValues = updatedFilters[`${filter.field}-${filter.type}`];
          if (isEmpty(filterCurrentValues)) {
            updatedFilters[`${filter.field}-${filter.type}`] = [values];
          } else {
            updatedFilters[`${filter.field}-${filter.type}`][dateFilterIndex] = values;
          }
          setaddDateFilterr(false);
        }
      } else {
        updatedFilters[`${filter.field}-${filter.type}`].splice(dateFilterIndex, 1);
      }
      dispatch(
        updateChart({
          ...chart,
          filters: updatedFilters,
        })
      );
      track({
        eventName: HBEventName.ChartFiltered,
        data: { chartId: chart.id, filter: filter.field },
      });
    }
  };

  const getOptionsForFilter: (filter: UserFilter) => ReactNode = filter => {
    if (filterOptions[filter.entity as DashboardFilterType] === undefined && !isLoading) {
      dispatch(
        fetchDashboardOptions({
          type: filter.entity as DashboardFilterType,
          dependsOn: filter.dependsOn ?? filter.selectedEntity,
        })
      );
    } else {
      return filterOptions[filter.entity as DashboardFilterType]?.map(fo => (
        <Option key={fo.id} value={fo.name} searchName={fo.searchName}>
          <Tooltip zIndex={10000} title={<Trans i18nKey={fo.tooltip} components={{ br: <br /> }} />}>
            {fo.name}
          </Tooltip>
        </Option>
      ));
    }
  };

  const addDateRangeSelector: React.MouseEventHandler<HTMLElement> = () => {
    setaddDateFilterr(true);
  };

  const localizedRanges = useMemo(() => {
    return Object.fromEntries(
      Object.entries(ranges).map(([k, v]) => {
        const convertedRangeKey = Object.keys(DateRange)[Object.values(DateRange).indexOf((k as unknown) as DateRange)];
        return [t(convertedRangeKey), v];
      })
    );
  }, [ranges]);

  const renderFilter: (filter: UserFilter) => ReactNode = filter => {
    switch (filter.type) {
      case 1: {
        const dateRanges = chart?.filters && chart.filters[`${filter.field}-${filter.type}`];
        return (
          <Space direction="vertical" align="center">
            {dateRanges && dateRanges.length > 0 ? (
              <>
                {dateRanges.map((range: [string, string], index: number) => {
                  return (
                    <HBRangeDatePicker
                      key={index}
                      onChange={onDateFilterUpdated(filter, index)}
                      value={range}
                      allowEmptyValue={true}
                      ranges={localizedRanges}
                    />
                  );
                })}
                {addDateFilterr && (
                  <HBRangeDatePicker
                    key={dateRanges?.length}
                    onChange={onDateFilterUpdated(filter, dateRanges.length)}
                    value={null}
                    allowEmptyValue={true}
                    ranges={localizedRanges}
                  />
                )}
              </>
            ) : (
              <HBRangeDatePicker
                key={0}
                onChange={onDateFilterUpdated(filter, 0)}
                value={null}
                allowEmptyValue={true}
                ranges={localizedRanges}
              />
            )}
            <Button onClick={addDateRangeSelector} type="text" shape="round">
              <PlusOutlined style={{ color: primaryColor }} />
            </Button>
          </Space>
        );
      }
      case 2: {
        return (
          <Select
            style={{ width: "15rem" }}
            mode="multiple"
            allowClear
            onChange={onFilterSelected(filter)}
            showSearch
            loading={isLoading}
            placeholder={`Select a ${filter.entity}`}
            defaultValue={chart?.filters ? (chart.filters[`${filter.field}-${filter.type}`] as string[]) : []}
            filterOption={(input, option) =>
              option?.props.searchName ? option.props.searchName.toLowerCase().includes(input.toLowerCase()) : false
            }
          >
            {getOptionsForFilter(filter)}
          </Select>
        );
      }
      case 3: {
        return (
          <Spin spinning={isLoading}>
            <Select
              style={{ width: "15rem" }}
              mode="multiple"
              allowClear
              onChange={onFilterSelected(filter)}
              showSearch
              placeholder={`Select a ${filter.entity}`}
              defaultValue={chart?.filters ? (chart.filters[`${filter.field}-${filter.type}`] as string[]) : []}
            >
              {getOptionsForFilter(filter)}
            </Select>
            <Select
              style={{ width: "15rem" }}
              mode="multiple"
              allowClear
              onChange={onFilterSelected(filter)}
              showSearch
              placeholder={`Select a ${filter.entity}`}
              defaultValue={chart?.filters ? (chart.filters[`${filter.field}-${filter.type}`] as string[]) : []}
            >
              {getOptionsForFilter(filter)}
            </Select>
          </Spin>
        );
      }
    }
  };

  const getOptionTitle = (optionId: number) => {
    switch (optionId) {
      case 1:
        return t("DateFilter");
      case 3:
        return t("ProjectFilter");
      case 4:
        return t("UserFilter");
      case 5:
        return t("ReviewComparison");
      case 7:
        return t("DictionaryFilter");
    }
  };

  const getMenuItems = () => {
    return options.map(option => {
      if (option.type === 0) {
        return undefined;
      }
      return (
        <Popover key={option.id} placement="right" content={() => renderFilter(option)} trigger="click">
          <Button type="text" shape="round" key={option.id}>
            {getOptionTitle(option.id)}
          </Button>
        </Popover>
      );
    });
  };

  const getMenu = () => {
    return (
      <Space style={{ backgroundColor: "white" }} direction="vertical">
        {getMenuItems()}
      </Space>
    );
  };

  return (
    <Popover placement="bottomLeft" content={getMenu()} trigger="click">
      <a>
        <FilterFilled className="dashboard-menu-icon" />
      </a>
    </Popover>
  );
}
