import { FieldType } from "./page";

export enum UploadModalType {
  FileUpload = "FileUpload",
  TaskFileUpload = "TaskFileUpload",
  InspectionStatus = "InspectionStatus",
  AddComment = "AddComment",
  TodoInspectionCheckpointFixed = "TodoInspectionCheckpointFixed",
  TodoInspectionCheckpointNotFixed = "TodoInspectionCheckpointNotFixed",
  MannualyUpdateFixedInspection = "MannualyUpdateFixedInspection",
}

export enum TableAddButtonType {
  AddInline = "addInline",
  AddModal = "addModal",
  CustomModal = "customModal",
  UploadFile = "uploadFile",
  AddComment = "addComment",
  UploadTaskFile = "uploadTaskFile",
  AddSubTask = "addSubTask",
}

export enum Status {
  Active = "Active",
  Inactive = "Inactive",
  InActive = "InActive",
  Broken = "Broken",
}

export enum AuthType {
  OTP = "OTP",
  SSO = "SSO",
  Username = "Username",
}

export enum TwoFactorType {
  None = "None",
  Email = "Email",
  Sms = "Sms",
}

export enum OpenClosedStatus {
  Open = "Open",
  //temp
  Opened = "Opened",
  Closed = "Closed",
  Canceled = "Canceled",
}

export enum TasksPageStatus {
  Assigned = "Assigned",
  Unassigned = "Unassigned",
  PastDueDate = "PastDueDate",
}

export enum AppointmentStatus {
  Completed = "Completed",
  Draft = "Draft",
  PartiallyCompleted = "PartiallyCompleted",
  Proccessing = "Proccessing",
  Sent = "Sent",
  Canceled = "Canceled",
}

export enum TaskPriority {
  Highest = "Critical",
  High = "High",
  Medium = "Medium",
  Low = "Low",
  Lowest = "None",
}

export enum TraineeStatus {
  Pending = "Pending",
  Missing = "Missing",
  Attended = "Attended",
}

export enum CertificateTraineeStatus {
  Active = "Active",
  Expired = "Expired",
  Pending = "Pending",
  IsNoLongerNeeded = "IsNoLongerNeeded",
}

export enum Role {
  Owner = "Owner",
  Recipient = "Recipient",
  Viewer = "Viewer",
  Manager = "Manager",
}

export type AdditionalProps = {
  name: string;
  type: FieldType;
  dictionaryId?: number | null;
  propertyId: number;
  value?: string;
  actAsIdentifier?: boolean;
};

export type CustomProperties = {
  propertyId: number;
  propertyValue: string;
};

export enum HistoryLogFieldName {
  FileUrl = "FileUrl",
  CompletedAt = "CompletedAt",
  Comment = "Comment",
  Status = "Status",
}

export enum HistoryLogEntityType {
  Certificate = "Certificate",
  Employee = "Employee",
  Equipment = "Equipment",
  Inspection = "Inspection",
  Location = "Location",
  OrgUnit = "OrgUnit",
  Training = "Training",
}

export enum HistoryLogOperations {
  Created = "Created",
  Updated = "Updated",
  Deleted = "Deleted",
}

export type HistoryLog = {
  entityId: number;
  userId: number;
  userName: string;
  operation: HistoryLogOperations;
  timeStamp: string;
  fieldName: string;
  oldValue: string;
  newValue: string;
  date?: string;
  pdfLink?: string;
};

export type TaskComment = {
  createDate: string;
  createdByUserId: number;
  taskId: number;
  comment: string;
  userName: string;
  id: number;
};

export type TaskEvent = {
  additionalData: string;
  createDate: string;
  createdByUserId: number;
  id: number;
  taskId: number;
  type: HistoryLogOperations | string;
  pdfLink: string;
};

/**
 * Enum of all currently supported Privileged Entities
 */
export enum PrivilegedEntityType {
  Location = "location",
  Equipment = "equipment",
  OrgUnit = "orgUnit",
  Employee = "employee",
  Certificate = "certificate",
  Training = "training",
  HbTask = "hbTask",
  Project = "project",
}

export interface ExportMapping {
  type: string;
  property: string;
  value: string;
}

export enum LocalStorageKeys {
  JWT = "jwt",
  LANG = "lang",
  LANDING_PAGE_JWT = "landingPageJwt",
  VITRE_ENTERED = "vitreEntered",
  //login mode
  PAS_LOGIN_MODE = "PASLoginMode",
  //login
  Username = "lsunn",
  Email = "lsee",
  SSO = "lussoo",
  Phone = "lupnn",
  TableColumnsWidth = "TableColumnsWidth",
  QuestionGroupTableView = "QuestionGroupTableView",
}

export enum ParamsKeys {
  SSO_NOT_FOUND = "ssonotfound",
  SSO_USER_NOT_FOUND = "ssousernotfound",
  LANG = "lang",
  LOCAL_REDIRECT = "localRedirect",
  RETURN_URL = "returnUrl",
  MOBILE = "mobile",
  NOREDIRECT = "noredirect",
  KEY = "key",
  RETURN_URL_KEY = "Key",
  MODE = "mode",
  PAGE = "page",
  LANGUAGE = "language",
  ACTIONS_MODE = "actionsMode",
  FILTERS_SEARCH_PREFIX = "filters",
  FILTERS_IDS_PREFIX = "ids",
  COLUMNS_SEARCH_PREFIX = "columns",
  TODO_LIST = "todolist",
  LG = "lg",
  ID = "id",
  USER_ID = "userId",
  PARENT_ID = "parentId",
  INSTANT_CLOSE = "instantClose",
}
/**
 * Privileged data used to identify single privilege relation in the accountable tab of any entity
 */
export type PrivilegeData = {
  /**
   * Auto generated ID for row manipulation /react side only/
   */
  id?: string | undefined;
  /**
   *  User id - set only if this privilege is related to user
   */
  userId: number | string | null;
  /**
   * Org Unit id - set only if this privilege is related to org Unit
   */
  orgUnitId: number | string | null;
  /**
   * Status of the User/OrgUnit (for visualization purposes)
   */
  status: Status | null;
  /**
   * External identifier of the privileged entity
   */
  externalId: string | null;
  /**
   *  Name of the privileged entity
   */
  name: string;
  /**
   *  Identifies if the privileged entity is external to the system or not
   */
  isExternal: boolean | null;
  /**
   *  Access role that this privilege grants to the entity
   */
  role: Role;
  /**
   * OldRole is used only in change requests, it should provide the name of the old role that we want to change
   */
  oldRole?: Role | null | undefined;
  /**
   * Manage privileges assigned to entire organizational unit (OU) branches.
   */
  isAssignedToOUBranch: boolean;
  /**
   * Used only in rendering to identify if the record is new (not yet saved) or not
   */
  staging?: boolean;
  /**
   * Used only in rendering to identify if the record is new (not yet saved) or not
   */
  privilegedEntityId?: number | null;
};

/**
 * Used to create generic slicers for any entity privileges and contains all the required parameters to properly build the request
 */
export interface PrivilegeRequestData {
  /**
   * ID of the entity that we are trying to process privileges for,
   * if entity state is set to null, then the thunk will try to resolve this from singleData?.id if possible
   */
  id: number | null;
  /**
   * Backend controller name that should be requested for this particular entity
   */
  entityType: PrivilegedEntityType;
}

/**
 * Request used to update/delete entity that also include the entity
 */
export interface PrivilegeRequestWithEntityData<TEntity> extends PrivilegeRequestData {
  /**
   * Reference to the entity that is being modified
   */
  entity: TEntity;
}

export interface PrivilegeDataWithEntiyType {
  /**
   * Reference to the entity that is being modified
   */
  entity: PrivilegeData;
  /**
   * Backend controller name that should be requested for this particular entity
   */
  entityType: PrivilegedEntityType;
}

/**
 * Specific request for privilege update including the new role
 */
export interface PrivilegeRequestChangeData<TEntity> extends PrivilegeRequestWithEntityData<TEntity> {
  /**
   * New role that should be set
   */
  role?: Role;

  /**
   * Update OUlvl
   */
  isAssignedToOUBranch?: boolean;
}

export interface EmployeeOrgUnitRelations {
  employee: {
    externalId: string;
    status: Status;
    id: number;
    name: string;
    isExternal: boolean;
  } | null;
  orgUnit: {
    externalId: string;
    status: Status;
    id: number;
    name: string;
  } | null;
}

export type ExplicitAdditionalProps = {
  name: string;
  type: FieldType;
  id: number;
  defaultValue: string;
  dictionaryId: number;
  sortOrder: number;
  actAsIdentifier: boolean;
};

export type UpdateCustomProps = Record<string, unknown>;

export enum ActionTypes {
  PDF = "DownloadPDF",
}

export enum TodoListCardStatus {
  Close = "Closed",
  Open = "Open",
  Late = "Late",
  AwaitForApproval = "AwaitForApproval",
  SentForApproval = "SentForApproval",
  Proccessing = "Proccessing",
}

export interface CreateAppointmentResponseModel {
  appointmentAlreadyExists: boolean;
  urlToAppointment: string;
  warningMessage: string | null;
}

export enum AuthStatus {
  Authorized = "Authorized",
  VerificationCodeSent = "VerificationCodeSent",
  VerificationUsernameRequired = "VerificationUsernameRequired",
  UserMustChangePassword = "VerificationUsernameRequired",
  UserContextChanged = "VerificationUsernameRequired",
}

export const NoValueKey = "[{No%%%Value}]";

export enum ExludedPages {
  LandingPage = "landingpage",
  LivePDF = "formresults",
  TodoList = "todolist",
}

export enum AllInspectionsStatus {
  Invalid = "Invalid",
  Valid = "Valid",
}

export const ALL_INSPECTION_STATUS_LOCALIZATION_KEY = {
  [AllInspectionsStatus.Invalid]: "AllInspectionsInvalid",
  [AllInspectionsStatus.Valid]: "AllInspectionsValid",
};
