import { ReactComponent as LocationIcon } from "../../../../media/location-icon.svg";
import { locationEntitySelectors } from "../../../../selectors";
import { EmployeeBool } from "../../../../types/employee";
import { Location } from "../../../../types/location";
import { BaseField, InputTypes } from "../../../../types/page";
import { ALL_INSPECTION_STATUS_LOCALIZATION_KEY } from "../../../../types/utility";
import { boolOptions, options } from "../common";
import {
  constructTableTree,
  fetchLocationById,
  fetchLocationTypeById,
  localizeText,
  renderOptionIcon,
} from "../utilities";

export const nameField: BaseField<Location> = {
  id: "name",
  title: "FieldName",
  value: entity => entity.name,
  type: InputTypes.INPUT,
  required: true,
  placeholder: "Choose",
  optionsSelector: () => null,
};

export const statusField: BaseField<Location> = {
  id: "status",
  title: "FieldStatus",
  Icon: ({ value }) => renderOptionIcon(value.status),
  value: e => e.status,
  localizedValue: e => localizeText(e.status),
  type: InputTypes.DROPDOWN,
  options,
  optionsSelector: () => null,
};

export const typeIdField: BaseField<Location> = {
  id: "typeId",
  title: "FieldType",
  value: entity => (entity.typeId ? entity.typeId : null),
  valueText: entity => fetchLocationTypeById(entity.typeId),
  type: InputTypes.AUTOCOMPLETE,
  placeholder: "Choose",
  optionsSelector: state =>
    state.locationType.data?.map(lt => ({
      id: lt.id,
      label: lt.name,
    })),
};

export const isSiteField: BaseField<Location> = {
  id: "isSite",
  title: "FieldSite",
  value: e => e.isSite,
  valueText: e => localizeText(e.isSite ? EmployeeBool.Yes : EmployeeBool.No),
  type: InputTypes.BOOLEAN,
  options: boolOptions,
  optionsSelector: () =>
    boolOptions.map(l => ({
      id: l.id,
      label: localizeText(l.label),
    })),
};

export const externalIdField: BaseField<Location> = {
  id: "externalId",
  title: "ExternalID",
  value: e => e.externalId,
  type: InputTypes.INPUT,
  required: true,
  placeholder: "Enter",
  optionsSelector: () => null,
  enableMobileScanners: true,
};

export const parentIdField: BaseField<Location> = {
  id: "parentId",
  title: "Parent",
  Icon: LocationIcon,
  value: e => e.parentId,
  valueText: e => {
    if (!e.parentId) {
      return null;
    }
    return fetchLocationById(e.parentId);
  },
  type: InputTypes.TREESELECT,
  optionsSelector: () => null,
  treeData: {
    treeDataSelector: locationEntitySelectors.basic,
    treeDataSet: (dataSet, currentEntityId) =>
      constructTableTree(
        {
          data: dataSet,
          itemValueKey: "id",
          itemLabelKey: "name",
          currentEntityValue: currentEntityId,
        },
        true
      ),
  },
  placeholder: "Root",
  placeholderMobileFirst: "Root",
  tabSwitchAction: {
    tabSwitch: true,
    action: () => "location",
  },
  enableMobileScanners: true,
};

export const allInspectionStatusField: BaseField<Location> = {
  id: "AllInspectionsStatus",
  title: "AllInspectionsStatusLabel",
  value: e => e.allInspectionsStatus,
  localizedValue: entity => localizeText(ALL_INSPECTION_STATUS_LOCALIZATION_KEY[entity.allInspectionsStatus]),
  type: InputTypes.TEXT,
  optionsSelector: () => null,
};

export const assetsInspectionStatusField: BaseField<Location> = {
  id: "AssetsInspectionStatus",
  title: "AssetsInspectionStatusLabel",
  value: e => e.assetsInspectionStatus,
  localizedValue: entity => localizeText(ALL_INSPECTION_STATUS_LOCALIZATION_KEY[entity.assetsInspectionStatus]),
  type: InputTypes.TEXT,
  optionsSelector: () => null,
};

export const primaryFields: BaseField<Location>[] = [nameField, statusField, typeIdField, isSiteField];

export const secondaryFields: BaseField<Location>[] = [
  externalIdField,
  parentIdField,
  allInspectionStatusField,
  assetsInspectionStatusField,
];
