import { BinaryFilter, Filter, Query, TimeDimension, TQueryOrderArray, UnaryFilter } from "@cubejs-client/core";

import { Chart } from "../../../../types/dashboard";
import { ROOT_MARKER } from "../ChartOptions/HirarchialDataControl/HierarchicalDataControlContextProvider";
import { ranges } from "../ChartOptions/Menus/ChartFilterMenu";
import { hirachialColumns } from "../Helpers";

export const dimensionCubeKey = (dimension: string) => {
  return dimension.split(".")[0];
};

export const dimensionName = (dimension: string) => {
  return dimension.split(".")[1];
};

export type UserFilterTimeDimension = TimeDimension & {
  userFilter: boolean;
};

export const convertRangesToDates = (query: Query) => {
  if (query.timeDimensions) {
    let updatedTimeDimensions = query.timeDimensions ? [...query.timeDimensions] : [];
    updatedTimeDimensions = updatedTimeDimensions.map(td => {
      if (td.dateRange && !Array.isArray(td.dateRange)) {
        const convertedRange = Object.values(ranges)[Object.keys(ranges).indexOf(td.dateRange as string)];
        if (convertedRange) {
          return {
            ...td,
            dateRange: (convertedRange.map(d => d.format("YYYY-MM-DD")) as [string, string]) || td.dateRange,
          };
        }
      }
      return td;
    });
    query.timeDimensions = updatedTimeDimensions;
  }
};

export const filtersManipulation = (query: Query, chart: Chart) => {
  const updatedFilters = query.filters ? [...query.filters] : [];
  let updatedTimeDimensions = query.timeDimensions ? [...query.timeDimensions] : [];
  const updatedDimensions = query.dimensions ? [...query.dimensions] : [];

  for (const f in chart?.filters) {
    const filterDimension = f.split("-")[0];
    const rawFilterValues = chart?.filters?.[f];
    const dateFilterValues = rawFilterValues?.[0];
    const dateIndicators = ["date", "expiration"];

    if (
      dateIndicators.some(indicator => filterDimension.includes(indicator)) &&
      dateFilterValues &&
      dateFilterValues.length > 0
    ) {
      if (updatedTimeDimensions.length === 1) {
        if (Array.isArray(dateFilterValues)) {
          if (dateFilterValues.length === 1) {
            updatedTimeDimensions = updatedTimeDimensions.map(tDimension => {
              if (tDimension.dimension === filterDimension) {
                if (dateFilterValues[0] !== null) {
                  return { ...tDimension, dateRange: dateFilterValues[0] as string, userFilter: true };
                } else {
                  return { ...tDimension, dateRange: undefined };
                }
              }
              return tDimension;
            });
          } else {
            updatedTimeDimensions = updatedTimeDimensions.map(tDimension => {
              if (tDimension.dimension === filterDimension) {
                return { ...tDimension, dateRange: dateFilterValues };
              }
              return tDimension;
            });
          }
        } else {
          updatedTimeDimensions = updatedTimeDimensions.map(tDimension => {
            if (tDimension.dimension === filterDimension) {
              return {
                ...tDimension,
                dateRange: tDimension.dateRange,
                granularity: chart?.filters?.[f] || tDimension.granularity,
              };
            }
            return tDimension;
          });
        }
      }

      updatedDimensions.push(filterDimension);
    }

    if (
      !dateIndicators.some(indicator => filterDimension.includes(indicator)) &&
      rawFilterValues?.length > 0 &&
      filterDimension
    ) {
      updatedFilters.push({
        member: filterDimension,
        operator: "equals",
        values: rawFilterValues,
      } as any);
    }
  }
  query.filters = updatedFilters;
  query.timeDimensions = updatedTimeDimensions;
};

export const calculatePivotConfig = (query: Query) => {
  if (query.dimensions && query.dimensions.length > 0) {
    if (query.timeDimensions && query.timeDimensions.length > 0 && query.timeDimensions![0].granularity) {
      return {
        x: [
          ...query!.dimensions.slice(1, query.dimensions.length),
          ...query.timeDimensions?.map(t => `${t.dimension}.${t.granularity}`),
        ],
        y: ["measures", ...[query.dimensions[0]]],
      };
    } else {
      if (query!.dimensions.length === 1) {
        return {
          x: query!.dimensions,
          y: ["measures"],
        };
      }
      return {
        x: query!.dimensions.slice(1, query.dimensions.length),
        y: ["measures", ...[query.dimensions[0]]],
      };
    }
  } else {
    if (query.timeDimensions && query.timeDimensions.length > 0 && query.timeDimensions![0].granularity) {
      return {
        x: [...query.timeDimensions?.map(t => `${t.dimension}.${t.granularity}`)],
        y: ["measures"],
      };
    } else {
      return {
        x: [],
        y: ["measures"],
      };
    }
  }
};

export const ordersManipulation = (query: Query, chart: Chart) => {
  const userOrders = chart.userOrders;
  const orders = chart.orders;

  if (userOrders && orders) {
    const updatedOrders: TQueryOrderArray = userOrders.reduce<TQueryOrderArray>((acc, uo) => {
      const order = orders.find(o => o.sort === uo.order);
      if (order && order.value !== "none") {
        acc.push([uo.label, order.value]);
      }
      return acc;
    }, []);
    query.order = updatedOrders;
  }
};

export const dimensionsManipulation = (query: Query, chart: Chart) => {
  const slicers = chart.dimensions;
  if (slicers) {
    const updatedSlicers = slicers.reduce<string[]>((acc, slicer) => {
      if (chart.userDimensions?.find(ud => ud.dimension === slicer)) {
        acc.push(slicer);
      }
      return acc;
    }, []);
    if (updatedSlicers.length > 0) {
      query.dimensions = updatedSlicers;
    }
    const timeDimensionGranularity =
      chart.filters &&
      query.timeDimensions &&
      query.timeDimensions.length > 0 &&
      chart.filters[`${query.timeDimensions[0].dimension}-0`];
    query.timeDimensions = query.timeDimensions?.map<TimeDimension>(dim => ({
      ...dim,
      granularity: timeDimensionGranularity,
    }));
  }
};

export const drillIntoHirarchialQuery = (query: Query, chart: Chart, value: string, hirarchialDimensionKey: string) => {
  let depth = 0;
  query.filters = query.filters?.reduce<Filter[]>((acc, filter) => {
    const f = filter as BinaryFilter | UnaryFilter;
    if (
      f.member &&
      dimensionCubeKey(f.member) === dimensionCubeKey(hirarchialDimensionKey) &&
      dimensionName(f.member) === "depth" &&
      f.values
    ) {
      depth = parseInt(f.values[0]) + 1;
      acc.push({ ...f, values: [`${depth}`] } as BinaryFilter | UnaryFilter);
    } else if (
      f.member &&
      dimensionCubeKey(f.member) === dimensionCubeKey(hirarchialDimensionKey) &&
      dimensionName(f.member) === "parentName"
    ) {
      acc.push({ ...f, values: [value] } as BinaryFilter | UnaryFilter);
    } else {
      acc.push(filter);
    }
    return acc;
  }, []);

  const hirarchialDimensionFilter = query.filters?.find(filter => {
    const f = filter as BinaryFilter | UnaryFilter;
    return (
      f.member &&
      dimensionCubeKey(f.member) === dimensionCubeKey(hirarchialDimensionKey) &&
      dimensionName(f.member) === "parentName"
    );
  });

  if (!hirarchialDimensionFilter) {
    query.filters?.push({
      member: `${dimensionCubeKey(hirarchialDimensionKey)}.parentName`,
      operator: "equals",
      values: [value],
    });
  }

  return depth;
};

export const manipulateHirarchialDrillDownQuery = (
  query: Query,
  chart: Chart,
  depth?: number,
  value?: string,
  hirarchialDimensionKey?: string
) => {
  query.filters = query.filters?.reduce<Filter[]>((acc, filter) => {
    const f = filter as BinaryFilter | UnaryFilter;
    if (f.member && hirachialColumns.includes(dimensionCubeKey(f.member))) {
      if (dimensionName(f.member) === "depth") {
        acc.push({ ...f, values: [`${depth}`] } as BinaryFilter | UnaryFilter);
      } else if (dimensionName(f.member) === "parentName") {
        if (value && value !== ROOT_MARKER && value !== "") {
          acc.push({ ...f, values: [value] } as BinaryFilter | UnaryFilter);
        }
      } else {
        acc.push(filter);
      }
    } else {
      acc.push(filter);
    }
    return acc;
  }, []);

  const hirarchialDimensionFilter = query.filters?.find(filter => {
    const f = filter as BinaryFilter | UnaryFilter;
    return (
      f.member &&
      hirarchialDimensionKey &&
      dimensionCubeKey(f.member) === dimensionCubeKey(hirarchialDimensionKey) &&
      dimensionName(f.member) === "parentName"
    );
  });

  const hirarchialDimensionDepthFilter = query.filters?.find(filter => {
    const f = filter as BinaryFilter | UnaryFilter;
    return (
      f.member &&
      hirarchialDimensionKey &&
      dimensionCubeKey(f.member) === dimensionCubeKey(hirarchialDimensionKey) &&
      dimensionName(f.member) === "depth"
    );
  });

  if (hirarchialDimensionKey && !hirarchialDimensionFilter && value && value !== "" && value !== ROOT_MARKER) {
    query.filters?.push({
      member: `${dimensionCubeKey(hirarchialDimensionKey)}.parentName`,
      operator: "equals",
      values: [value],
    });
  }

  if (hirarchialDimensionKey && !hirarchialDimensionDepthFilter) {
    query.filters?.push({
      member: `${dimensionCubeKey(hirarchialDimensionKey)}.depth`,
      operator: "equals",
      values: [`${depth}`],
    });
  }
};
