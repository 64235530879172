import { Badge } from "antd";
import dayjs from "dayjs";

import {
  ColumnManagementAction,
  ExportAction,
  GetFiltersUrl,
  ImportAction,
  ResetColumnsWidthAction,
} from "../../../../actions/menuActions";
import { DATE_FORMAT_WITHOUT_TIME } from "../../../../components/HBComponents/DatePicker/HBDatePicker";
import AddEmployeeSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddEmployeeSection";
import AddFileSection from "../../../../components/SingleViewCardMobileFirst/components/addEntityTemplates/AddFileSection/AddFileSection";
import EquipmentAccountableMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/accountable/EquipmentAccountableMobileCardTemplate";
import ContextActionsMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/ContextActionsMobileCardTemplate";
import FilesMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/FilesMobileCardTemplate";
import HistoryLogMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/HistoryLogMobileCardTemplate";
import InspectionEquipmentMobileCardTemplate from "../../../../components/SingleViewCardMobileFirst/components/templates/inspections/InspectionEquipmentMobileCardTemplate";
import { ReactComponent as Logo } from "../../../../media/eq-list-icon.svg";
import { ReactComponent as EquipmentIcon } from "../../../../media/equipment-icon.svg";
import { equipmentSelectors, inspectionSelectors, locationEntitySelectors, pageSelectors } from "../../../../selectors";
import { fetchHistoryLog } from "../../../../store/slices/commonThunks";
import {
  addEquipment,
  clearEquipmentError,
  createEqTemplate,
  createNewAccountableEntryTemplate,
  fetchEquipmentsCards,
  fetchPaginatedEquipments,
  fetchSingleEquipment,
  patchLocationId,
  removeFiles,
  resetCurrentPage,
  resetSearchResults,
  searchEquipment,
  updateEquipment,
  updateLocationId,
  uploadFile,
} from "../../../../store/slices/equipment";
import { fetchEquipmentTypes } from "../../../../store/slices/equipmentType";
import { resetActiveFiltersAndAddNew } from "../../../../store/slices/filter";
import { deleteMultipleEntityPrivileges, getEntityPrivileges } from "../../../../store/slices/privileges";
import { Equipment, EquipmentPage } from "../../../../types/equipment";
import { File } from "../../../../types/files";
import { InspectionSingleView } from "../../../../types/inspection";
import { Location } from "../../../../types/location";
import { CategoryPage, SectionType } from "../../../../types/page";
import {
  HistoryLogEntityType,
  PrivilegeData,
  PrivilegedEntityType,
  TableAddButtonType,
} from "../../../../types/utility";
import {
  contextActionsTableColumns,
  filesTableColumns,
  historyLogTableColumns,
  locationsTableColumns,
} from "../common";
import {
  fetchEqTypeById,
  localizeText,
  pickSpecificColumns,
  prepareDynamicColumns,
  prepareDynamicColumnsPaginated,
  removeColumnFilters,
} from "../utilities";
import {
  equipmentAccountableActionField,
  equipmentAccountableExternalIdField,
  equipmentAccountableIsAssignedToOUBranchField,
  equipmentAccountableNameField,
  equipmentAccountableRoleField,
  equipmentAccountableTabColumns,
  equipmentsTableColumns,
  inspectionTableColumns,
} from "./equipmentConfigPageData";
import {
  expirationField,
  allInspectionStatusField,
  locationIdField,
  nameField,
  primaryFields,
  secondaryFields,
  serialNumberField,
  statusField,
  typeIdField,
} from "./fields";

const equipmentConfigPage = (): CategoryPage<EquipmentPage> => ({
  id: "equipment",
  title: "PluginEquipmentName",
  entityEndpoint: "/Equipment",
  PageIcon: Logo,
  hasGroupView: true,
  hasListView: true,
  intializeDataActions: [fetchEquipmentTypes],
  primaryErrorSelector: equipmentSelectors.error,
  lastUpdatedSelector: equipmentSelectors.equipmentLastUpdatedText,
  // fetchPrimaryData: fetchEquipmentCustomProps,
  breadcrumbSingleValue: e => {
    return { id: e.id?.toString(), label: e.name };
  },
  addButton: {
    label: "AddEquipment",
    // eslint-disable-next-line no-console
    action: () => console.log("Create new equipment"),
  },
  summaryCard: {
    CardIcon: Logo,
    remotelyUpdatedFieldIDs: ["locationId"],
    dependentFields: (e, isNewEntity, manuallyChangedFields) => {
      const typeName = e.typeId && fetchEqTypeById(e.typeId);
      const nameDefaultValue = isNewEntity
        ? manuallyChangedFields?.includes("name")
          ? e.name
          : e.typeId && e.serialNumber && `${typeName} ${e.serialNumber}`
        : "";
      return isNewEntity
        ? [
            {
              id: "name",
              parentId: "typeId",
              defaultValue: nameDefaultValue,
            },
            {
              id: "name",
              parentId: "serialNumber",
              defaultValue: nameDefaultValue,
            },
            // (e.name !== nameDefaultValue && e.name && e.typeId && e.serialNumber && nameDefaultValue) || e.name,
          ]
        : [];
    },
    mobileSingleView: {
      summary: {
        icon: Logo,
        title: nameField,
        id: serialNumberField,
        status: statusField,
        mainContextRelation: locationIdField,
        date: typeIdField,
        allInspectionStatus: allInspectionStatusField,
      },
      sections: [
        {
          type: SectionType.AdministrationSection,
          fields: [],
        },
        {
          type: SectionType.ContextSection,
          fields: [],
        },
        {
          type: SectionType.DatesSection,
          fields: [expirationField],
        },
      ],
    },

    primaryFields: primaryFields,
    secondaryFields: secondaryFields,
  },

  tabsPanel: [
    {
      key: "actions",
      label: "FieldActions",
      tabSelector: equipmentSelectors.actions,
      columns: removeColumnFilters(contextActionsTableColumns()),
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: ContextActionsMobileCardTemplate,
    },
    {
      key: "inspections",
      label: "PluginInspectionName",
      tabSelector: equipmentSelectors.inspections,
      type: "table",
      rowSelection: true,
      columns: removeColumnFilters(inspectionTableColumns) as [],
      generateDynamicColumns: (props, data) =>
        removeColumnFilters(prepareDynamicColumns<InspectionSingleView>(props, data)),
      customPropertiesSelector: inspectionSelectors.inspectionCustomProperties,
      mobileCardTemplate: InspectionEquipmentMobileCardTemplate,
    },
    // TODO: Change once we have more information about each tab
    // {
    //   key: "surveys",
    //   label: "Surveys",
    //   tabSelector: equipmentSelectors.inspections,
    //   type: "table",
    //   addButton: true,
    //   addButtonAction: () => console.log("add button clicked"),
    //   addButtonOptions: addButtonOptions,
    //   rowSelection: true,
    //   columns: inspectionColumns,
    // },
    // TODO: Change once we have more information about each tab
    // {
    //   key: "tasks",
    //   label: "Tasks",
    //   tabSelector: equipmentSelectors.inspections,
    //   type: "table",
    //   addButton: true,
    //   addButtonAction: () => console.log("add button clicked"),
    //   addButtonOptions: addButtonOptions,
    //   rowSelection: true,
    //   columns: inspectionColumns,
    // },
    // {
    //   key: "historyLog",
    //   label: "History Log",
    //   tabSelector: equipmentSelectors.inspections,
    //   type: "table",
    //   addButton: false,
    //   rowSelection: false,
    //   columns: inspectionColumns,
    // },
    // TODO: Change once we have more information about each tab
    // {
    //   key: "issues",
    //   label: "Issues",
    //   tabSelector: equipmentSelectors.inspections,
    //   type: "table",
    //   addButton: true,
    //   addButtonAction: () => console.log("add button clicked"),
    //   addButtonOptions: addButtonOptions,
    //   rowSelection: true,
    //   columns: inspectionColumns,
    // },
    {
      key: "location",
      label: "AppointmentLocationLink",
      tabSelector: locationEntitySelectors.basic,
      columns: removeColumnFilters(locationsTableColumns) as [],
      rowSelection: true,
      type: "tree",
      // currentSelection: () => fetchCurrentLocationId(),
      changeNewEntityParent: updateLocationId,
      changeExistingEntityParent: patchLocationId,
      selectedKeyId: "locationId",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<Location>(props, data)),
      customPropertiesSelector: locationEntitySelectors.locationCustomProperties,
    },
    {
      key: "accountable",
      label: "Accountable",
      tabSelector: equipmentSelectors.equipmentAccountableRelations,
      tabDataThunk: id => getEntityPrivileges({ id: +id, entityType: PrivilegedEntityType.Equipment }),
      columns: removeColumnFilters(equipmentAccountableTabColumns) as [],
      rowSelection: true,
      type: "table",
      addButton: {
        action: createNewAccountableEntryTemplate,
        label: "AddEmployee",
        type: TableAddButtonType.AddInline,
        mobileAddButtonTemplate: AddEmployeeSection,
        actionField: equipmentAccountableActionField,
        addEntityFields: [
          equipmentAccountableNameField,
          equipmentAccountableExternalIdField,
          equipmentAccountableRoleField,
          equipmentAccountableIsAssignedToOUBranchField,
        ],
      },
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      dropdownButtonActions: [
        {
          label: "DeleteBulk",
          action: entities =>
            deleteMultipleEntityPrivileges({
              entity: entities as PrivilegeData[],
              entityType: PrivilegedEntityType.Equipment,
              id: null,
            }),
          type: "bulk",
          changesConfirmation: {
            body: "ConfirmEntitiesRemoval",
            okText: "Remove",
            cancelText: "Keep",
            addValueToBody: false,
            addValueToOkText: false,
          },
        },
      ],
      mobileCardTemplate: EquipmentAccountableMobileCardTemplate,
    },
    {
      key: "files",
      label: "Files",
      tabSelector: equipmentSelectors.files,
      columns: removeColumnFilters(filesTableColumns("equipment")) as [],
      rowSelection: true,
      addButton: {
        action: createNewAccountableEntryTemplate,
        label: "AddFile",
        // controlUploadModal: true,
        type: TableAddButtonType.UploadFile,
        uploadFileAction: uploadFile,
        mobileAddButtonTemplate: AddFileSection,
      },
      type: "table",
      generateDynamicColumns: (props, data) => removeColumnFilters(prepareDynamicColumns<File>(props, data)),
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      defaultFilters: [
        {
          key: "expirationDate",
          value: dayjs().format(),
          textValue: null,
          filterType: "moreThan",
          label: "Expiration date",
          fieldType: "dateRange",
        },
        {
          key: "expirationDate",
          value: dayjs().add(10, "year").format(),
          textValue: null,
          filterType: "lessThan",
          label: "Expiration date",
          fieldType: "dateRange",
        },
      ],
      dropdownButtonActions: [
        {
          label: localizeText("DeleteBulk"),
          action: entities => removeFiles(entities.map(x => x.id) as number[]),
          type: "bulk",
          changesConfirmation: {
            body: "Removing the files could not be reversible, Are you should you wish to remove the files?",
            okText: "Yes I wish to remove the files",
            cancelText: "Keep the files",
          },
        },
      ],
      mobileCardTemplate: FilesMobileCardTemplate,
    },
    {
      key: "historyLog",
      label: "HistoryLog",
      tabDataThunk: id => fetchHistoryLog({ entityId: +id, entityType: HistoryLogEntityType.Equipment }),
      tabSelector: equipmentSelectors.equipmentHistoryLog,
      columns: removeColumnFilters(historyLogTableColumns) as [],
      rowSelection: true,
      type: "table",
      generateDynamicColumns: () => [],
      customPropertiesSelector: pageSelectors.nullableCustomPropertiesSelector,
      mobileCardTemplate: HistoryLogMobileCardTemplate,
    },
  ],
  listView: {
    table: {
      fetchPaginatedData: fetchPaginatedEquipments,
      searchResultsSelector: equipmentSelectors.equipmentSearchResults,
      possibleResultsSelector: equipmentSelectors.equipmentPossibleResults,
      tableSelector: equipmentSelectors.all,
      columns: equipmentsTableColumns,
      rowSelection: true,
      defaultColumnKeys: ["serialNumber", "name", "typeId", "locationId", "expirationData", "status"],
      type: "paginatedTable",
      searchPaginatedData: searchEquipment,
      resetSearchResult: resetSearchResults,
      resetCurrentPage: resetCurrentPage,
    },
    generateDynamicColumns: (props, data) => prepareDynamicColumnsPaginated<Equipment>(props, data),
  },
  groupView: {
    fetchCardThunk: fetchEquipmentsCards,
    cardsSelector: equipmentSelectors.allEquipmentCards,
    CardIcon: EquipmentIcon,
    totalCountField: {
      id: "total",
      title: "FieldTotal",
      value: e => e.total,
      action: entity => ({
        action: () =>
          resetActiveFiltersAndAddNew([
            {
              entityKey: "equipment",
              pageId: "equipment",
              filter: {
                filterType: "equals",
                key: "typeId",
                label: "FieldType",
                textValue: entity.equipmentType,
                value: entity.equipmentTypeId,
                fieldType: "text",
              },
            },
          ]),
        link: {
          path: "/equipment",
          state: { viewType: "list" },
        },
      }),
    },
    primaryFields: [
      {
        id: "name",
        title: "FieldName",
        value: e => e.equipmentType,
        disableOnClickEvent: true,
      },
    ],
    secondaryFields: [
      {
        id: "active",
        title: "FieldActive",
        value: e => e.active,
        icon: () => <Badge color="#02D924" />,
        placeholder: "Enter",
        action: entity => ({
          action: () =>
            resetActiveFiltersAndAddNew([
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "equals",
                  key: "status",
                  label: "FieldStatus",
                  textValue: "Active",
                  value: "Active",
                  fieldType: "text",
                },
              },
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "equals",
                  key: "typeId",
                  label: "FieldType",
                  textValue: entity.equipmentType,
                  value: entity.equipmentTypeId,
                  fieldType: "text",
                },
              },
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "moreThan",
                  key: "expiration",
                  label: "FieldExpiration",
                  textValue: dayjs().format(),
                  value: dayjs().format(),
                  fieldType: "dateRange",
                  isBlank: true,
                },
              },
            ]),
          link: {
            path: "/equipment",
            state: { viewType: "list" },
          },
        }),
      },
      {
        id: "aboutToExpired",
        title: "AboutToExpire",
        value: e => e.aboutToExpired,
        icon: () => <Badge color="#B7B7B7" />,
        placeholder: "Enter",
        action: entity => ({
          action: () =>
            resetActiveFiltersAndAddNew([
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "moreThan",
                  key: "expiration",
                  label: "FieldExpiration",
                  textValue: dayjs().format(DATE_FORMAT_WITHOUT_TIME),
                  value: dayjs().format(),
                  fieldType: "dateRange",
                },
              },
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "lessThan",
                  key: "expiration",
                  label: "FieldExpiration",
                  textValue: dayjs().add(60, "day").format(DATE_FORMAT_WITHOUT_TIME),
                  value: dayjs().add(60, "day").format(),
                  fieldType: "dateRange",
                },
              },
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "equals",
                  key: "typeId",
                  label: "FieldType",
                  textValue: entity.equipmentType,
                  value: entity.equipmentTypeId,
                  fieldType: "text",
                },
              },
            ]),
          link: {
            path: "/equipment",
            state: { viewType: "list" },
          },
        }),
      },
      {
        id: "broken",
        title: "FieldBroken",
        value: e => e.broken,
        icon: () => <Badge color="#D9042B" />,
        placeholder: "Enter",
        action: entity => ({
          action: () =>
            resetActiveFiltersAndAddNew([
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "equals",
                  key: "status",
                  label: "FieldStatus",
                  textValue: "Broken",
                  value: "Broken",
                  fieldType: "text",
                },
              },
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "equals",
                  key: "typeId",
                  label: "FieldType",
                  textValue: entity.equipmentType,
                  value: entity.equipmentTypeId,
                  fieldType: "text",
                },
              },
            ]),
          link: {
            path: "/equipment",
            state: { viewType: "list" },
          },
        }),
      },
      {
        id: "expired",
        title: "AlreadyExpired",
        value: e => e.expired,
        icon: () => <Badge color="#D9042B" />,
        placeholder: "Enter",
        action: entity => ({
          action: () =>
            resetActiveFiltersAndAddNew([
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "lessThan",
                  key: "expiration",
                  label: "FieldExpiration",
                  textValue: dayjs().format(DATE_FORMAT_WITHOUT_TIME),
                  value: dayjs().format(),
                  fieldType: "dateRange",
                },
              },
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "equals",
                  key: "typeId",
                  label: "FieldType",
                  textValue: entity.equipmentType,
                  value: entity.equipmentTypeId,
                  fieldType: "text",
                },
              },
            ]),
          link: {
            path: "/equipment",
            state: { viewType: "list" },
          },
        }),
      },
      {
        id: "inActive",
        title: "FieldInActive",
        value: e => e.inactive,
        icon: () => <Badge color="#FF8515" />,
        placeholder: "Enter",
        action: entity => ({
          action: () =>
            resetActiveFiltersAndAddNew([
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "equals",
                  key: "status",
                  label: "FieldStatus",
                  textValue: "InActive",
                  value: "InActive",
                  fieldType: "text",
                },
              },
              {
                entityKey: "equipment",
                pageId: "equipment",
                filter: {
                  filterType: "equals",
                  key: "typeId",
                  label: "FieldType",
                  textValue: entity.equipmentType,
                  value: entity.equipmentTypeId,
                  fieldType: "text",
                },
              },
            ]),
          link: {
            path: "/equipment",
            state: { viewType: "list" },
          },
        }),
      },
    ],
  },
  listSearch: {
    columns: pickSpecificColumns<Equipment>(equipmentsTableColumns, "serialNumber", "status", "name"),
    keys: ["serialNumber", "name"],
  },
  entityProperties: [
    { property: "serialNumber", title: "ID", required: true },
    { property: "name", title: "Name", creation: true },
    { property: "equipmentTypeName", title: "Type", creation: true },
    { property: "locationIdentifier", title: "Location" },
    { property: "status", title: "Status" },
    { property: "expiration", title: "ExpirationDate" },
    { property: "owners", title: "Owners" },
    { property: "recipients", title: "Recipients" },
  ],
  isLoading: pageSelectors.isEquipmentPageLoading,
  primarySingleEntitySelector: equipmentSelectors.single,
  customPropertiesSelector: equipmentSelectors.equipmentCustomProperties,
  fetchSingle: fetchSingleEquipment,
  createNewEntityTemplate: createEqTemplate,
  clearError: clearEquipmentError,
  createNewEntity: addEquipment,
  updateEntity: updateEquipment,
  listViewActions: [ImportAction, ExportAction, ColumnManagementAction, ResetColumnsWidthAction, GetFiltersUrl],
});

export default equipmentConfigPage;
